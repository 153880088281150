import Vue from 'vue'
import axios from '@/libs/axios'

const getCacheKey = (endpoint, params) => {
  let hash = endpoint

  if (typeof params === 'object') {
    Object.keys(params).forEach(prop => {
      hash += `__${prop}_${params[prop]}`
    })
  }

  return hash
}

export default {
  namespaced: true,
  state: {
    cache: {
      items: {},
      validFor: 1000 * 60 * 10, // 10 minutes
    },
    userData: {
      id: null,
      languageCode: '',
      participant: {
        firstName: '',
        lastName: '',
        bio: '',
      },
      userGroup: {
        title: '',
      },
    },
    selectedEntity: {},
  },
  getters: {
    cached: (state, getters) => key => {
      if (getters.isValid(key)) {
        return state.cache.items[key].data
      }

      return undefined
    },

    userData: state => state.userData,

    isValid: state => key => {
      const item = state.cache.items[key]
      const timestamp = Date.now()

      return item && state.cache.validFor > timestamp - item.timestamp
    },

    selectedEntity: state => state.selectedEntity,
  },
  mutations: {
    cache: (state, payload) => {
      // Cache item can be used to stop an ajax request without storing the data on this object
      state.cache.items[payload.key] = {
        timestamp: Date.now(),
        data: payload.data ? payload.data : null,
      }
    },

    userData: (state, payload) => {
      Object.entries(payload).forEach(([i, val]) => {
        Vue.set(state.userData, i, val)
      })
    },

    selectedEntity: (state, payload) => {
      state.selectedEntity = payload
    },

    clearCache: state => {
      state.cache.items = {}
    },
  },
  actions: {
    jwtLogin(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/users/login', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUserGroupRights(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/users/usergrouprights/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchEpayParam(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/payments/epay-payment', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCongressRooms(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/congress-rooms/list/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCongress(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/congresses/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateUserLanguage({ getters, commit }, data) {
      axios.put('/users/language', { ...data })
      commit('userData', {
        ...getters.userData,
        ...data,
      })

      localStorage.setItem('userData', JSON.stringify(getters.userData))
    },
    updateUserData({ getters, commit }, data) {
      return axios.put('/users/profile', { ...data }).then(res => {
        commit('userData', {
          ...getters.userData,
          ...data,
        })

        localStorage.setItem('userData', JSON.stringify(getters.userData))

        return res
      })
    },
    fetchGenericList({ getters, commit }, payload) {
      const queryParams = payload.params
      const endpoint = payload.url
      const key = getCacheKey(endpoint, queryParams)

      if (getters.isValid(key)) {
        return getters.cached(key)
      }

      return new Promise((resolve, reject) => {
        axios
          .get(endpoint, { params: queryParams })
          .then(response => {
            commit('cache', {
              key,
              data: response,
            })

            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchUsers({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', {
        params: queryParams,
        url: 'users/list',
      })
    },
    fetchUserGroups({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', {
        params: queryParams,
        url: 'user-groups/list',
      })
    },
    fetchPrimaryGroups({ dispatch }) {
      return dispatch('fetchGenericList', {
        params: { secondary: false },
        url: 'user-groups/secondary/list',
      })
    },
    fetchSecondaryGroups({ dispatch }) {
      return dispatch('fetchGenericList', {
        params: { secondary: true },
        url: 'user-groups/secondary/list',
      })
    },
    fetchQuestionnaireSections({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', {
        params: queryParams,
        url: 'questionnaire-sections/list',
      })
    },
    fetchQuestionnaireUnits({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', {
        params: queryParams,
        url: 'questionnaire-units/list',
      })
    },
    fetchPlainSubscriptions({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', {
        params: queryParams,
        url: 'user-groups/subscriptions-list',
      })
    },
    fetchCongresses({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', {
        params: queryParams,
        url: 'congresses/list',
      })
    },
    fetchCongressStatuses({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', {
        params: queryParams,
        url: 'congresses/statuses/list',
      })
    },
    fetchCongressCategories({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', {
        params: queryParams,
        url: 'congresses/categories/list',
      })
    },
    fetchPollCategories({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', {
        params: queryParams,
        url: 'poll-categories/list',
      })
    },
    fetchQuestionnaires({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', {
        params: queryParams,
        url: 'questionnaires/list',
      })
    },
    fetchQuestionnaireCategories({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', {
        params: queryParams,
        url: 'questionnaire-categories/list',
      })
    },
    fetchQuestionnaireCenters({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', {
        params: queryParams,
        url: 'questionnaire-centers/list',
      })
    },
    fetchAssignedQuestionnaireCenters({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', {
        params: queryParams,
        url: 'questionnaires/centers/list',
      })
    },
    fetchQuestionnaireFields({ dispatch }, questionnaireId) {
      return dispatch('fetchGenericList', {
        url: `questionnaire-fields/questionnaire/${questionnaireId}`,
      })
    },
    fetchPollFields({ dispatch }, pollId) {
      return dispatch('fetchGenericList', {
        url: `poll-questions/fields/${pollId}`,
      })
    },
    fetchQuestionnairePlainFields({ dispatch }, questionnaireId) {
      return dispatch('fetchGenericList', {
        url: `questionnaires/fields/${questionnaireId}`,
      })
    },
    fetchFieldTypes({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', {
        params: queryParams,
        url: 'questionnaires/field-types/list',
      })
    },
    fetchBanks({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', {
        params: queryParams,
        url: 'banks/list',
      })
    },
    fetchBankAccounts({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', {
        params: queryParams,
        url: 'bank-accounts/list',
      })
    },
    fetchCategories({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', {
        params: queryParams,
        url: 'categories/list',
      })
    },
    fetchCompanies({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', {
        params: queryParams,
        url: 'companies/list',
      })
    },
    fetchCountries({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', {
        params: queryParams,
        url: 'countries/list',
      })
    },
    fetchPaymentMethods({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', {
        params: queryParams,
        url: 'payment-methods/list',
      })
    },
    fetchPostCategories({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', {
        params: queryParams,
        url: 'post-categories/list',
      })
    },
    fetchRoles({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', { params: queryParams, url: 'roles' })
    },
    fetchSubscriptions({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', {
        params: queryParams,
        url: 'user-groups/subscriptions',
      })
    },
    fetchRegistrationPeriods({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', {
        params: queryParams,
        url: 'registration-periods/list',
      })
    },
    fetchLanguages({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', {
        params: queryParams,
        url: 'languages/list',
      })
    },
    fetchVenues({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', {
        params: queryParams,
        url: 'venues/list',
      })
    },
    fetchAllRooms({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', {
        params: queryParams,
        url: 'congress-rooms/list',
      })
    },
    fetchTopics({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', {
        params: queryParams,
        url: 'topics/list',
      })
    },
    fetchSpecialties({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', {
        params: queryParams,
        url: 'specialties/list',
      })
    },
    fetchProfessions({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', {
        params: queryParams,
        url: 'professions/list',
      })
    },
    fetchWorkingGroups({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', {
        params: queryParams,
        url: 'working-groups/list',
      })
    },
    fetchHospitals({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', {
        params: queryParams,
        url: 'hospitals/list',
      })
    },
    fetchClinics({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', {
        params: queryParams,
        url: 'clinics/list',
      })
    },
    fetchPrefectures({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', {
        params: queryParams,
        url: 'prefectures/list',
      })
    },
    fetchParticipants({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', {
        params: queryParams,
        url: 'participants/list',
      })
    },
    fetchMemberTypes({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', {
        params: queryParams,
        url: 'member-types/list',
      })
    },
    fetchJobPositions({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', {
        params: queryParams,
        url: 'job-positions/list',
      })
    },
    fetchHospitalTypes({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', {
        params: queryParams,
        url: 'hospital-types/list',
      })
    },
  },
}
